module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Joel Hoelting","start_url":"/","background_color":"#2D3073","theme_color":"#2D3073","display":"minimal-ui","icon":"src/assets/images/joel_hoelting_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"97a7847a8dafdabf327406c4aefb3080"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
